import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  centeredFab: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();

  const handleSignIn = () => {
    props.authorization.signIn();
  };
  return (
    <div>
      <Fab
        onClick={handleSignIn}
        variant="extended"
        className={classes.centeredFab}
      >
        {/** <SignIn className={classes.extendedIcon} /> */}
        Sign In With Google
      </Fab>
    </div>
  );
}
