import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AuxillaryUserMenu from "./AuxillaryUserMenu";

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: -1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  video: {
    background: "#fefefe",
    position: "relative",
    borderRadius: "5px",
    border: "2px solid black",
    width: "calc(min(100vw, 77.777vh) *.9)",
    height: "calc(min(128.5vw, 100vh) *.9)",
  },
}));

export default function Auxillary(props) {
  const classes = useStyles();
  const video = React.useRef(null);

  const handleCanPlay = () => {
    video.current.play();
  };

 

  React.useEffect(() => {
    const constraints = {
      video: {
        facingMode: "environment",
      },
    };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      props.media.setAuxillaryPreviewStream(stream);
      props.connection.openAuxillary();
      video.current.srcObject = props.media.auxillaryPreviewStream;
    });

    props.media.on("auxillaryPreviewStreamUpdated", () => {
      video.current.srcObject = props.media.auxillaryPreviewStream;
    });
    
  }, [props.connection, props.media]);

  

  return (
    <div className={classes.root}>
      <div className={classes.videoContainer}>
        <video
          ref={video}
          className={classes.video}
          onCanPlay={handleCanPlay}
        ></video>
      </div>
      <AuxillaryUserMenu
        authorization={props.authorization}
        connection={props.connection}
      />
    </div>
  );
}
