import { Auth, Hub } from "aws-amplify";
import Emitter from "./Emitter.js";

class Authorization extends Emitter {
  constructor() {
    super();
    this.user = null;
    this.credentials = null;
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        //|| payload.event === "signOut"
        //We dont need to check signOut action since after Auth.signOut
        //the app reload completely, we just leave the spinner working
        this.checkAuthState();
      }
    });
  }

  checkAuthState() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.currentCredentials().then((credentials) => {
          this.user = user;
          this.credentials = credentials;
          const msg = {
            user: user,
            credentials: credentials,
          };
          this.emit("authChange", msg);
          console.log(("authChange", msg));
        });
      })
      .catch((err) => {
        this.emit("authChange", {});
      });
  }

  signIn() {
    this.emit("startAuthChange");
    Auth.federatedSignIn({ provider: "Google" });
  }

  signOut() {
    Auth.signOut()
      .then(() => {
        this.user = null;
        this.credentials = null;
      })
      .catch((err) => console.log(err));
    this.emit("startAuthChange");
  }
}

export default Authorization;
