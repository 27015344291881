import Emitter from "./Emitter.js";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import { SignalingClient, Role } from "amazon-kinesis-video-streams-webrtc";
//import _ from "lodash";

class Connection extends Emitter {
  constructor() {
    super();

    // CONNECTIONS
    this.peerConnection = null;
    this.peerConnectionStatsInterval = null;

    this.auxillaryConnection = null;
    this.auxillaryConnectionStatsInterval = null;

    this.auxillaryReceivingConnection = null;
    this.auxillaryReceivingStatsInterval = null;

    // CLIENTS
    this.masterSignalingClient = null;
    this.viewerSignalingClient = null;
    this.auxillarySignalingClient = null;

    // KINESIS VIDEO CLIENT
    this.kinesisVideoClient = null;

    // USER INFORMATION
    this.user = null;
    this.credentials = null;
  }

  setPeerConnection(connection) {
    this.peerConnection = connection;
    this.emit("peerConnectionUpdated", this.peerConnection)
  }
  getChannelNameFromEmail(email) {
    return email.replace("@", "At");
  }

  init(user, credentials) {
    this.user = user;
    this.credentials = credentials;
    this.kinesisVideoClient = new AWS.KinesisVideo({
      apiVersion: "2017-09-30",
      region: "us-east-1",
      credentials: Auth.essentialCredentials(credentials),
    });
  }

  async openMaster() {
    const channelName = this.getChannelNameFromEmail(
      this.user.attributes.email
    );
    const channelInfo = await this.getChannelInfo(channelName, Role.MASTER);

    // Create Signaling Client
    this.masterSignalingClient = new SignalingClient({
      channelARN: channelInfo.channelARN,
      channelEndpoint: channelInfo.endpointsByProtocol.WSS,
      role: channelInfo.role,
      region: "us-east-1",
      credentials: Auth.essentialCredentials(this.credentials),
      systemClockOffset: this.kinesisVideoClient.config.systemClockOffset,
    });

    this.masterSignalingClient.on("open", async () => {
      this.emit("onMasterOpen", null);
      console.log("[MASTER] Connected to signaling service");
    });

    const configuration = await this.getIceServerConfiguration(
      channelInfo.channelARN,
      channelInfo.endpointsByProtocol
    );

    this.masterSignalingClient.on("sdpOffer", async (offer, remoteClientId) => {
      console.log("[MASTER] Received SDP offer from client: " + remoteClientId);

      if (remoteClientId === "AUXILLARY") {
        // Create a new peer connection using the offer from the given client
        this.auxillaryReceivingConnection = new RTCPeerConnection(
          configuration
        );

        // Poll for connection stats
        if (!this.auxillaryReceivingConnectionStatsInterval) {
          this.auxillaryReceivingConnectionStatsInterval = setInterval(
            () =>
              this.auxillaryReceivingConnection.getStats().then(() => {
                /*onStatsReport*/
              }),
            1000
          );
        }

        // Send any ICE candidates to the other peer
        this.auxillaryReceivingConnection.addEventListener(
          "icecandidate",
          ({ candidate }) => {
            if (candidate) {
              console.log(
                "[MASTER] Sending ICE candidate to client: " + remoteClientId
              );
              this.masterSignalingClient.sendIceCandidate(
                candidate,
                remoteClientId
              );
            }
          }
        );

        // As remote tracks are received, add them to the remote view
        this.auxillaryReceivingConnection.addEventListener("track", (event) => {
          console.log(
            "[MASTER] Received remote track from client: " + remoteClientId
          );
          this.media.setIncomingAuxillaryStream(event.streams[0]);
        });

        // DO NOT CREATE OUTPUT STREAMS

        await this.auxillaryReceivingConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log(
          "[MASTER] Creating SDP answer for client: " + remoteClientId
        );
        await this.auxillaryReceivingConnection.setLocalDescription(
          await this.auxillaryReceivingConnection.createAnswer({
            offerToReceiveAudio: false,
            offerToReceiveVideo: true,
          })
        );

        console.log("[MASTER] Sending SDP answer to client: " + remoteClientId);
        this.masterSignalingClient.sendSdpAnswer(
          this.auxillaryReceivingConnection.localDescription,
          remoteClientId
        );
      } else {
        // PEER

        console.log(offer);
        console.log(offer.sdp);
        let offer_sdp = offer.sdp;
        console.log(offer_sdp);
        let index_1 = offer_sdp.indexOf("\ni=");
        let index_2 = offer_sdp.indexOf("\n", index_1 + 3);
        let info = offer_sdp.substring(index_1 + 2, index_2);
        console.log(info);

        // Create a new peer connection using the offer from the given client
        this.setPeerConnection(new RTCPeerConnection(configuration));

        this.peerDataChannel = this.peerConnection.createDataChannel(
          "kvsDataChannel"
        );
        this.peerConnection.ondatachannel = (event) => {
          event.channel.onmessage = () => {};
        };

        // Poll for connection stats
        if (!this.peerConnectionStatsInterval) {
          this.peerConnectionStatsInterval = setInterval(
            () => this.peerConnection.getStats().then(),
            1000
          );
        }

        // Send any ICE candidates to the other peer
        this.peerConnection.addEventListener(
          "icecandidate",
          ({ candidate }) => {
            if (candidate) {
              console.log(
                "[MASTER] Sending ICE candidate to client: " + remoteClientId
              );
              this.masterSignalingClient.sendIceCandidate(
                candidate,
                remoteClientId
              );
            }
          }
        );

        this.peerConnection.addEventListener(
          "iceconnectionstatechange",
          (Event) => {
            console.log("ICE Connection state Change", Event);
            console.log("State", Event.target.iceConnectionState);
          }
        );

        // As remote tracks are received, add them to the remote view
        this.peerConnection.addEventListener("track", (event) => {
          console.log("[MASTER] Received remote track from client. ");
          this.media.setIncomingPeerStream(event.streams[0]);
        });

        let outputStream = this.media.getOutputStream();

        outputStream
          .getTracks()
          .forEach((track) =>
            this.peerConnection.addTrack(track, outputStream)
          );

        await this.peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log(
          "[MASTER] Creating SDP answer for client: " + remoteClientId
        );
        await this.peerConnection.setLocalDescription(
          await this.peerConnection.createAnswer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.

        console.log("[MASTER] Sending SDP answer to client: " + remoteClientId);
        this.masterSignalingClient.sendSdpAnswer(
          this.peerConnection.localDescription,
          remoteClientId
        );
      }
    });

    this.masterSignalingClient.on(
      "iceCandidate",
      async (candidate, remoteClientId) => {
        console.log(
          "[MASTER] Received ICE candidate from client: " + remoteClientId
        );
        if (remoteClientId === "AUXILLARY") {
          this.auxillaryReceivingConnection.addIceCandidate(candidate);
        } else {
          // PEER
          this.peerConnection.addIceCandidate(candidate);
        }
      }
    );

    this.masterSignalingClient.on("close", () => {
      if (this.auxillaryReceivingConnection) {
        this.auxillaryReceivingConnection.close();
      }
      if (this.peerConnection) {
        this.peerConnection.close();
      }
      
      this.setPeerConnection(null);

      if (this.media.incomingPeerStream) {
        this.media.incomingPeerStream
          .getTracks()
          .forEach((track) => track.stop());
      }
      this.media.incomingPeerStream = null;

      if (this.peerConnectionStatsInterval) {
        clearInterval(this.peerConnectionStatsInterval);
        this.peerConnectionStatsInterval = null;
      }
      this.masterSignalingClient = null;
      this.emit("onMasterClose", null);
      console.log("[MASTER] Disconnected from signaling channel");
    });

    this.masterSignalingClient.on("error", () => {
      console.error("[MASTER] Signaling client error");
    });

    console.log("[MASTER] Starting master connection");
    this.masterSignalingClient.open();
  }

  async closeMaster() {
    console.log("[MASTER] Stopping master connection");
    if (this.masterSignalingClient) {
      this.masterSignalingClient.close();
    }
  }

  async openViewer(conf) {
    let channelName = this.getChannelNameFromEmail(conf.remoteEmail);
    let channelInfo = await this.getChannelInfo(channelName, Role.VIEWER);

    // Create Signaling Client
    this.viewerSignalingClient = new SignalingClient({
      channelARN: channelInfo.channelARN,
      channelEndpoint: channelInfo.endpointsByProtocol.WSS,
      role: channelInfo.role,
      clientId: "PEER",
      region: "us-east-1",
      credentials: Auth.essentialCredentials(this.credentials),
      systemClockOffset: this.kinesisVideoClient.config.systemClockOffset,
    });

    this.viewerSignalingClient.on("open", async () => {
      console.log("[VIEWER] Connected to signaling service");

      // Get a stream, add it to the peer connection

      let outputStream = this.media.getOutputStream();

      outputStream
        .getTracks()
        .forEach((track) => this.peerConnection.addTrack(track, outputStream));

      // Create an SDP offer to send to the master
      console.log("[VIEWER] Creating SDP offer");

      const offer = await this.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });

      let o = offer.sdp;
      let i1 = o.indexOf("\ns=");
      let i2 = o.indexOf("\n", i1 + 3);
      let o1 = o.substring(0, i2);
      let o2 = o.substring(i2);
      offer.sdp = o1 + "\ni={channel:12333221}" + o2;

      console.log("[VIEWER] Offer: ", offer);

      await this.peerConnection.setLocalDescription(offer);

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.

      console.log("[VIEWER] Sending SDP offer");
      this.viewerSignalingClient.sendSdpOffer(offer);
      console.log("[VIEWER] Generating ICE candidates");
    });

    this.viewerSignalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      console.log("[VIEWER] Received SDP answer");
      await this.peerConnection.setRemoteDescription(answer);
    });

    this.viewerSignalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log("[VIEWER] Received ICE candidate");
      this.peerConnection.addIceCandidate(candidate);
    });

    this.viewerSignalingClient.on("close", () => {
      console.log("[VIEWER] Disconnected from signaling channel");
      if (this.peerConnection) {
        this.peerConnection.close();
      }
      this.setPeerConnection(null);

      if (this.media.incomingPeerStream) {
        this.media.incomingPeerStream
          .getTracks()
          .forEach((track) => track.stop());
      }
      this.media.incomingPeerStream = null;

      if (this.peerConnectionStatsInterval) {
        clearInterval(this.peerConnectionStatsInterval);
        this.peerConnectionStatsInterval = null;
      }

      /*if (this.master.dataChannelByClientId) {
        this.master.dataChannelByClientId = {};
      }*/
      this.viewerSignalingClient = null;
    });

    this.viewerSignalingClient.on("error", (error) => {
      console.error("[VIEWER] Signaling client error: ", error);
    });

    // CREATE PEER CONNECTION
    const configuration = await this.getIceServerConfiguration(
      channelInfo.channelARN,
      channelInfo.endpointsByProtocol
    );

    this.setPeerConnection( new RTCPeerConnection(configuration) );

    if (true) {
      //formValues.openDataChannel) {
      /*viewer.dataChannel = viewer.peerConnection.createDataChannel(
        "kvsDataChannel"
      );
      viewer.peerConnection.ondatachannel = (event) => {
        event.channel.onmessage = onRemoteDataMessage;
      };*/
    }

    // Poll for connection stats
    this.peerConnectionStatsInterval = setInterval(
      () => this.peerConnection.getStats().then(),
      1000
    );

    // Send any ICE candidates to the other peer
    this.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        console.log("[VIEWER] Sending ICE candidate");
        this.viewerSignalingClient.sendIceCandidate(candidate);
      }
    });

    // As remote tracks are received, add them to the remote view
    this.peerConnection.addEventListener("track", (event) => {
      console.log("[VIEWER] Received remote track");
      this.media.setIncomingPeerStream(event.streams[0]);
    });

    console.log("[VIEWER] Starting viewer connection");
    this.viewerSignalingClient.open();
  }

  async closeViewer() {
    console.log("[MASTER] Stopping master connection");
    if (this.viewerSignalingClient) {
      this.viewerSignalingClient.close();
    }
  }

  async openAuxillary() {
    const channelName = this.getChannelNameFromEmail(
      this.user.attributes.email
    );
    const channelInfo = await this.getChannelInfo(channelName, Role.VIEWER);

    // Create Signaling Client
    this.auxillarySignalingClient = new SignalingClient({
      channelARN: channelInfo.channelARN,
      channelEndpoint: channelInfo.endpointsByProtocol.WSS,
      role: channelInfo.role,
      clientId: "AUXILLARY",
      region: "us-east-1",
      credentials: Auth.essentialCredentials(this.credentials),
      systemClockOffset: this.kinesisVideoClient.config.systemClockOffset,
    });

    this.auxillarySignalingClient.on("open", async () => {
      console.log("[AUXILLARY] Connected to signaling service");

      let outputStream = this.media.auxillaryPreviewStream;

      outputStream
        .getTracks()
        .forEach((track) =>
          this.auxillaryConnection.addTrack(track, outputStream)
        );

      // Create an SDP offer to send to the master
      console.log("[AUXILLARY] Creating SDP offer");

      const offer = await this.auxillaryConnection.createOffer({
        offerToReceiveAudio: false,
        offerToReceiveVideo: false,
      });

      console.log("[AUXILLARY] Offer: ", offer);

      await this.auxillaryConnection.setLocalDescription(offer);

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.

      console.log("[AUXILLARY] Sending SDP offer");
      this.auxillarySignalingClient.sendSdpOffer(
        this.auxillaryConnection.localDescription
      );
      console.log("[AUXILLARY] Generating ICE candidates");
    });

    this.auxillarySignalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      console.log("[AUXILLARY] Received SDP answer");
      await this.auxillaryConnection.setRemoteDescription(answer);
    });

    this.auxillarySignalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log("[AUXILLARY] Received ICE candidate");
      this.auxillaryConnection.addIceCandidate(candidate);
    });

    this.auxillarySignalingClient.on("close", () => {
      
      this.auxillaryConnection = null;
      if (this.auxillaryConnectionStatsInterval) {
        clearInterval(this.auxillaryConnectionStatsInterval);
      }
      this.auxillaryConnectionStatsInterval = null;
      this.emit("auxillarySignalingClientConnectionChange", null);
      console.log("[AUXILLARY] Disconnected from signaling channel");
    });

    this.auxillarySignalingClient.on("error", (error) => {
      console.error("[AUXILLARY] Signaling client error: ", error);
    });

    // CREATE PEER CONNECTION
    const configuration = await this.getIceServerConfiguration(
      channelInfo.channelARN,
      channelInfo.endpointsByProtocol
    );

    this.auxillaryConnection = new RTCPeerConnection(configuration);
    this.emit("auxillarySignalingClientConnectionChange", this.auxillaryConnection)

    if (true) {
      //formValues.openDataChannel) {
      /*viewer.dataChannel = viewer.peerConnection.createDataChannel(
        "kvsDataChannel"
      );
      viewer.peerConnection.ondatachannel = (event) => {
        event.channel.onmessage = onRemoteDataMessage;
      };*/
    }

    // Poll for connection stats
    this.auxillaryConnectionStatsInterval = setInterval(
      () => this.auxillaryConnection.getStats().then(() => {}),
      1000
    );

    // Send any ICE candidates to the other peer
    this.auxillaryConnection.addEventListener(
      "icecandidate",
      ({ candidate }) => {
        if (candidate) {
          console.log("[AUXILLARY] Sending ICE candidate");
          this.auxillarySignalingClient.sendIceCandidate(candidate);
        }
      }
    );

    // As remote tracks are received, add them to the remote view
    this.auxillaryConnection.addEventListener("track", (event) => {
      console.log("[AUXILLARY] Received remote track");
      //this.media.setIncomingPeerStream(event.streams[0]);
    });

    console.log("[AUXILLARY] Starting viewer connection");
    this.auxillarySignalingClient.open();
  }
  async closeAuxillary() {
    console.log("[AUXILLARY] Stopping auxillary connection");
    if (this.auxillarySignalingClient) {
      this.auxillarySignalingClient.close();
    }
  }

  async getChannelInfo(channelName, role) {
    // Get signaling channel ARN
    const describeSignalingChannelResponse = await this.kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      })
      .promise();

    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log("[MASTER] Channel ARN: ", channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await this.kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: role,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
      (endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      },
      {}
    );
    console.log("[MASTER] Endpoints: ", endpointsByProtocol);

    return { channelName, role, channelARN, endpointsByProtocol };
  }

  async getIceServerConfiguration(channelARN, endpointsByProtocol) {
    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels(
      {
        region: "us-east-1",
        credentials: Auth.essentialCredentials(this.credentials),
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
      }
    );

    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    if (true) {
      //(!formValues.natTraversalDisabled && !formValues.forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.us-east-1.amazonaws.com:443`,
      });
    }
    if (true) {
      // (!formValues.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }
    console.log("[MASTER] ICE servers: ", iceServers);

    return {
      iceServers,
      iceTransportPolicy: false ? "relay" : "all", //formValues.forceTURN
    };
  }
}
export default Connection;
