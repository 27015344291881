import Emitter from "./Emitter";

import VideoStreamMerger from "video-stream-merger";

class Media extends Emitter {
  constructor() {
    super();
    this.manual = false;
    this.audioInputOptions = [];
    this.audioOutputOptions = [];
    this.videoInputOptions = [];

    // MEDIA ID
    this.videoInputPlayer = false;
    this.videoInputBoard = false;
    this.audioInput = false;
    this.audioOutput = false;

    // ACTUAL STREAMS
    this.incomingPeerStream = null;
    this.incomingAuxillaryStream = null;
    this.playerStream = null;
    this.boardStream = null;
    this.auxillaryPreviewStream = null;

    this.merger = new VideoStreamMerger({
      width: 1900, // Width of the output video
      height: 1080, // Height of the output video
      fps: 25, // Video capture frames per second
      clearRect: true, // Clear the canvas every frame
      audioContext: null, // Supply an external AudioContext (for audio effects)
    });
    this.merger.start();
  }

  initDevices = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        this.enumerateDevices();
        this.startDeviceChangeListener();
      })
      .catch((e) => {
        console.log(e);
        this.emit("getUserMediaFailed", e);
      });
  };

  setIncomingPeerStream(stream) {
    this.incomingPeerStream = stream;
    this.emit("peerStreamUpdated", { stream });
  }

  setIncomingAuxillaryStream(stream) {
    this.incomingAuxillaryStream = stream;
    this.emit("auxillaryStreamUpdated", { stream });
    this.smartSelectDevices();
  }

  setAuxillaryPreviewStream(stream) {
    this.auxillaryPreviewStream = stream;
    this.emit("auxillaryPreviewStreamUpdated", { stream });
  }

  enumerateDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(this.registerDevices)
      .catch(() => {});
  };

  registerDevices = (deviceInfos) => {
    this.audioInputOptions = [];
    this.audioOutputOptions = [];
    this.videoInputOptions = [];
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      let option = {};
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === "audioinput") {
        option.text =
          deviceInfo.label || `microphone ${this.audioInputOptions.length + 1}`;
        this.audioInputOptions.push(option);
      } else if (deviceInfo.kind === "audiooutput") {
        option.text =
          deviceInfo.label || `speaker ${this.audioOutputOptions.length + 1}`;
        this.audioOutputOptions.push(option);
      } else if (deviceInfo.kind === "videoinput") {
        option.text =
          deviceInfo.label || `camera ${this.videoInputOptions.length + 1}`;
        this.videoInputOptions.push(option);
      } else {
        console.log("Some other kind of source/device: ", deviceInfo);
      }
    }

    this.emit("mediaDevicesUpdated", {
      audioInputOptions: this.audioInputOptions,
      videoInputOptions: this.videoInputOptions,
      audioOutputOptions: this.audioOutputOptions,
    });

    if (this.manual === false) {
      this.smartSelectDevices();
    }
  };

  startDeviceChangeListener = () => {
    navigator.mediaDevices.addEventListener("devicechange", (event) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(this.registerDevices)
        .catch(() => {});
    });
  };

  smartSelectDevices = () => {
    if (this.videoInputOptions.length === 1) {
      this.setVideoInputPlayer(this.videoInputOptions[0].value);
      this.setVideoInputBoard("AUXILLARY");
    }
    if (this.videoInputOptions.length > 1) {
      this.setVideoInputPlayer(this.videoInputOptions[1].value);
      this.setVideoInputBoard(this.videoInputOptions[0].value);
    }

    if (this.audioInputOptions.length > 0) {
      this.setAudioInput(this.audioInputOptions[0].value);
    }
    if (this.audioOutputOptions.length > 0) {
      this.setAudioOutput(this.audioOutputOptions[0].value);
    }
  };

  getOutputStream() {
    return this.merger.result;
  }

  setVideoInputPlayer(mediaId) {
    this.videoInputPlayer = mediaId;
    if (!mediaId) {
      this.setPlayerStream(null);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: { exact: mediaId } } })
        .then((stream) => {
          this.setPlayerStream(stream);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  setVideoInputBoard(mediaId) {
    this.videoInputBoard = mediaId;
    if (!mediaId) {
      this.setBoardStream(null);
    } else if (mediaId === "AUXILLARY") {
      this.setBoardStream(this.incomingAuxillaryStream);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: { exact: mediaId } } })
        .then((stream) => {
          this.setBoardStream(stream);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  setAudioInput(mediaId) {
    this.audioInput = mediaId;
    if (mediaId) {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: { exact: mediaId } } })
        .then((stream) => {
          this.setAudioStream(stream);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setAudioStream(null);
    }
  }

  setAudioOutput(mediaId) {
    this.audioOutput = mediaId;
  }

  setAudioStream(stream) {
    if (this.audioStream) {
      this.merger.removeStream(this.audioStream);
    }
    if (stream) {
      this.audioStream = stream;
      this.merger.addStream(stream, {
        mute: false,
      });
    }
  }

  setPlayerStream(stream) {
    if (this.playerStream) {
      this.merger.removeStream(this.playerStream);
    }
    this.playerStream = stream;
    if (stream) {
      this.merger.addStream(stream, {
        draw: (ctx, frame, done) => {
          let height = stream.getVideoTracks()[0].getSettings().height;
          let width = stream.getVideoTracks()[0].getSettings().width;

          this.mediaDeviceCrop = {
            x: 0,
            y: 0,
            h: height,
            w: width,
          };
          if (height < width) {
            this.mediaDeviceCrop.x = (width - height) / 2;
            this.mediaDeviceCrop.w = height;
          } else {
            this.mediaDeviceCrop.y = (height - width) / 2;
            this.mediaDeviceCrop.h = width;
          }

          ctx.clearRect(820, 0, 1080, 1080);
          let crop = this.mediaDeviceCrop;
          ctx.drawImage(
            frame,
            crop.x,
            crop.y,
            crop.w,
            crop.h,
            820,
            0,
            1080,
            1080
          );

          done();
        },
        mute: true, // we don't want sound from the screen (if there is any)
      });
    }
  }

  setBoardStream(stream) {
    if (this.boardStream) {
      this.merger.removeStream(this.boardStream);
    }

    this.boardStream = stream;

    if (stream) {
      this.merger.addStream(stream, {
        draw: (ctx, frame, done) => {
          let height = stream.getVideoTracks()[0].getSettings().height;
          let width = stream.getVideoTracks()[0].getSettings().width;

          let crop = {
            x: 0,
            y: 0,
            h: height,
            w: width,
          };

          let ratio = 820 / 1080;

          if (height * ratio < width) {
            let properWidth = height * ratio;
            crop.x = (width - properWidth) / 2;
            crop.w = properWidth;
          } else {
            let properHeight = width / ratio;
            crop.y = (height - properHeight) / 2;
            crop.h = properHeight;
          }

          ctx.clearRect(0, 0, 820, 1080);

          ctx.drawImage(frame, crop.x, crop.y, crop.w, crop.h, 0, 0, 820, 1080);
          done();
        },
        mute: true, // we don't want sound from the screen (if there is any)
      });
    }
  }
}
export default Media;
