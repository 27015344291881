import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: "12px",
    right: "12px",
  },
  pill: {
    border: "2px solid black",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "5px",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "rgba(256,256,256,.8)",
    zIndex: "1000",
  },
  text: {
    padding: "0 20px 0 12px",
    lineHeight: "16px",
  },
  name: {
    fontSize: "12px",
    fontWeight: "600",
  },
  status: {
    fontSize: "12px",
  },
  avatar: {
    height: "36px",
    borderRadius: "0 3px 3px 0",
    borderLeft: "2px solid black",
  },
}));

export default function UserMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const user = props.authorization.user;
  //console.log("user", user);
  const [isConnected, setIsConnected] = React.useState(
    props.connection.isConnected
  );

  props.connection.on("onMasterOpen", () => {
    setIsConnected(
      props.connection.masterSignalingClient &&
        props.connection.masterSignalingClient.readyState
    );
  });

  props.connection.on("onMasterClose", () => {
    setIsConnected(
      props.connection.masterSignalingClient &&
        props.connection.masterSignalingClient.readyState !== 1
    );
  });

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenChannel = () => {
    props.connection.openMaster();
    handleCloseMenu();
  };

  const handleCloseChannel = () => {
    props.connection.closeMaster();
    props.connection.closeViewer();
    handleCloseMenu();
  };

  const handleSignOut = () => {
    props.authorization.signOut();
  };

  return (
    <div className={classes.root}>
      <div className={classes.pill} onClick={handleOpenMenu}>
        <div className={classes.text}>
          <div className={classes.name}>{user.attributes.name}</div>
          <div className={classes.status}>
            {isConnected && <div>Connected</div>}
            {!isConnected && <div>Offline</div>}
          </div>
        </div>
        <img
          className={classes.avatar}
          alt={user.attributes.given_name}
          src={user.attributes.picture}
        />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
        {isConnected && (
          <MenuItem onClick={handleCloseChannel}>Disconnect</MenuItem>
        )}
        {!isConnected && (
          <MenuItem onClick={handleOpenChannel}>Connect</MenuItem>
        )}
      </Menu>
    </div>
  );
}
