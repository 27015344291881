import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "fixed",
    top: "12px",
    left: "12px",
    fontWeight: "600",
    border: "2px solid black",
    borderRadius: "5px",
    padding: "0 0 0 8px",
    fontSize: "18px",
    height: "40px",
    lineHeight: "37px",
    backgroundColor: "rgba(256,256,256,.8)",
    zIndex: "1000",
  },
  up: {
    backgroundColor: "#000000",
    color: "#fff",
    borderRadius: "0 3px 3px 0",
    display: "inline-block",
    marginLeft: "8px",
    paddingLeft: "8px",
    paddingRight: "6px",
  },
}));

export default function Logo(props) {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      OCHE
      <div className={classes.up}>UP</div>
    </div>
  );
}
