import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "-1",
  },
  video: {
    background: "#fefefe",
    position: "relative",
    borderRadius: "5px",
    width: "calc(min(100vw, 177.77vh) - 24px)",
    height: "calc(min(56.25vw, 100vh) - 24px)",
  },
}));

export default function RemoteMedia(props) {
  const classes = useStyles();
  const remoteVideo = React.useRef(null);

  React.useEffect(() => {
    remoteVideo.current.srcObject = props.media.incomingPeerStream;
  });

  props.media.on("peerStreamUpdated", () => {
    remoteVideo.current.srcObject = props.media.incomingPeerStream;
  });

  const handleCanPlay = () => {
    remoteVideo.current.play();
  };

  return (
    <div className={classes.root}>
      <video
        ref={remoteVideo}
        className={classes.video}
        onCanPlay={handleCanPlay}
      ></video>
    </div>
  );
}
