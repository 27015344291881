import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  centeredFab: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function LocalMedia(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [peerConnected, setPeerConnected] = React.useState(
    props.connection.peerConnection !== null
  );
  const connection = props.connection;

  const [remoteEmail, setRemoteEmail] = React.useState("");

  props.connection.on("peerConnectionUpdated", () => {
    setPeerConnected(props.connection.peerConnection !== null)
  })
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const joinChannel = () => {
    let conf = {
      remoteEmail: remoteEmail,
    };
    connection.openViewer(conf);
    handleClose();
  };

  return (
    <div>
      {!peerConnected && (
        <Fab
          onClick={handleClickOpen}
          variant="extended"
          className={classes.centeredFab}
        >
          <AddIcon className={classes.extendedIcon} />
          Start Session
        </Fab>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Start Session</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm your opponent is connected before starting the
            session.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="email"
                value={remoteEmail}
                onChange={(e) => {
                  setRemoteEmail(e.target.value);
                }}
                label="Opponents Email Address"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={joinChannel} color="primary">
            Start
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
