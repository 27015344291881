// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:761e7311-62d9-457d-8a64-5178a6d4a134",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tZRwGiNjX",
    "aws_user_pools_web_client_id": "4emcp0ko9qasdql5i2dsblf76o",
    "oauth": {
        "domain": "ocheup001-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.ocheup.com/login/",
        "redirectSignOut": "https://www.ocheup.com/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
