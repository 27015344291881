import React, { Component } from "react";
import Auxillary from "./Auxillary.js";

class App extends Component {
  /*constructor() {
    super();
  }
*/
  componentDidMount() {
    this.props.connection.init(
      this.props.authorization.user,
      this.props.authorization.credentials
    );
  }

  render() {
    return (
      <React.Fragment>
        <Auxillary
          connection={this.props.connection}
          media={this.props.media}
          authorization={this.props.authorization}
        />
      </React.Fragment>
    );
  }
}

export default App;
