import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import QRCode from "qrcode.react";

const LocalMediaContainer = styled.div`
   {
    background: #cccccc;
    border-radius: 5px;
    width: 240px;
    height: 135px;
    position: fixed;
    bottom: 12px;
    right: 12px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const QRContainer = styled.div`
   {
    width: 105px;
    height: 135px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LocalVideoElement = styled.video`
   {
    background: #cccccc;
    border-radius: 5px;
    width: 240px;
    height: 135px;
    position: absolute;
  }
`;

export default function LocalMedia(props) {
  const [open, setOpen] = React.useState(false);
  const localVideo = React.useRef(null);
  const auxillaryURL = window.location.origin + "/aux";

  React.useEffect(() => {
    localVideo.current.srcObject = props.media.getOutputStream();
  });

  const handleClickOpen = () => {
    setVideoInputPlayer(props.media.videoInputPlayer);
    setVideoInputBoard(props.media.videoInputBoard);
    setAudioInput(props.media.audioInput);
    setAudioOutput(props.media.audioOutput);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    props.media.setVideoInputPlayer(videoInputPlayer);
    props.media.setVideoInputBoard(videoInputBoard);
    props.media.setAudioInput(audioInput);
    props.media.setAudioOutput(audioOutput);
    props.media.manual = true;
    setOpen(false);
  };

  const handleCanPlay = () => {
    localVideo.current.play();
  };

  const [audioInputOptions, setAudioInputOptions] = React.useState(
    props.media.audioInputOptions
  );
  const [audioOutputOptions, setAudioOutputOptions] = React.useState(
    props.media.audioOutputOptions
  );
  const [videoInputOptions, setVideoInputOptions] = React.useState(
    props.media.videoInputOptions
  );

  const [hasAuxillaryStream, setHasAuxillaryStream] = React.useState(
    props.media.incomingAuxillaryStream !== null
  );

  const [audioInput, setAudioInput] = React.useState(props.media.audioInput);
  const [audioOutput, setAudioOutput] = React.useState(props.media.audioOutput);
  const [videoInputPlayer, setVideoInputPlayer] = React.useState(
    props.media.videoInputPlayer
  );
  const [videoInputBoard, setVideoInputBoard] = React.useState(
    props.media.videoInputBoard
  );

  props.media.on("mediaDevicesUpdated", () => {
    setAudioInputOptions(props.media.audioInputOptions);
    setAudioOutputOptions(props.media.audioOutputOptions);
    setVideoInputOptions(props.media.videoInputOptions);
  });

  props.media.on("auxillaryStreamUpdated", (stream) => {
    setHasAuxillaryStream(props.media.incomingAuxillaryStream !== null);
  });

  return (
    <div>
      <LocalMediaContainer onClick={handleClickOpen}>
        <LocalVideoElement
          ref={localVideo}
          autoplay
          muted
          onCanPlay={handleCanPlay}
        ></LocalVideoElement>
        {props.media.videoInputBoard === "AUXILLARY" && !hasAuxillaryStream && (
          <QRContainer>
            <QRCode
              value={auxillaryURL}
              size={80}
              renderAs="svg"
              level="L"
              bgColor="#cccccc"
            />
          </QRContainer>
        )}
      </LocalMediaContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Local Media</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the local devices you would like to use to broadcast
            your game.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                id="outlined-basic"
                label="Player Camera"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setVideoInputPlayer(e.target.value);
                }}
                value={videoInputPlayer}
              >
                <MenuItem value={false}>No Camera</MenuItem>
                {videoInputOptions.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                id="outlined-basic"
                label="Board Camera"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setVideoInputBoard(e.target.value);
                }}
                value={videoInputBoard}
              >
                <MenuItem value={false}>No Camera</MenuItem>
                <MenuItem value={"AUXILLARY"}>Cell Phone</MenuItem>
                {videoInputOptions.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                id="outlined-basic"
                label="Microphone"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setAudioInput(e.target.value);
                }}
                value={audioInput}
              >
                <MenuItem value={false}>No Mic</MenuItem>
                {audioInputOptions.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                id="outlined-basic"
                label="Speakers"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setAudioOutput(e.target.value);
                }}
                value={audioOutput}
              >
                <MenuItem value={false}>No Sound</MenuItem>
                {audioOutputOptions.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
