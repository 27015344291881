import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Primary from "./Primary";
import Aux from "./Aux";
import SignIn from "./SignIn";
import Logo from "./Logo";
import Spinner from "./Spinner";
import Authorization from "./js/Authorization.js";
import Connection from "./js/Connection.js";
import Media from "./js/Media.js";
import LocalForage from "localforage";
import { createBrowserHistory } from "history";

class App extends Component {
  constructor() {
    super();

    this.state = {
      user: null,
      loading: true,
      mode: false,
    };
  }

  componentDidMount() {
    this.authorization = new Authorization();

    var store = LocalForage.createInstance({
      name: "OcheUp",
    });

    let history = createBrowserHistory();
    if (window.location.pathname === "/auth/") {
      store.getItem("mode").then((mode) => {
        if (mode === "AUXILLARY") {
          this.setState({ mode: "AUXILLARY" });
          history.push("/aux");
        } else {
          this.setState({ mode: "PLAY" });
          history.push("/");
        }
      });
    } else {
      try {
        this.authorization.checkAuthState();
      } catch (err) {
        this.setState({ user: null, loading: false });
      }
    }
    if (window.location.pathname === "/aux") {
      this.setState({ mode: "AUXILLARY" });
      store.setItem("mode", "AUXILLARY");
    }
    if (window.location.pathname === "/") {
      this.setState({ mode: "PLAY" });
      store.setItem("mode", "PLAY");
    }

    this.connection = new Connection();
    

    this.authorization.on("authChange", (msg) => {
      if (msg.user) {
        this.media = new Media();
        this.connection.media = this.media;
      } else {
        this.media = null
        this.connection.media = null;
      }
      store.getItem("mode").then((mode) => {
        if (mode === "AUXILLARY") {
          this.setState({ mode: "AUXILLARY" });
          history.push("/aux");
        } else {
          this.setState({ mode: "PLAY" });
          history.push("/");
        }
      });
      this.setState({ ...this.state, user: msg.user, loading: false });
    });

    this.authorization.on("startAuthChange", () => {
      this.setState({ loading: true });
    });

    /*const url = window.location.href;
    if (!(url.indexOf("?code=") !== -1)) {
      //if its comming from google avoid check user logged, wait for hub
      try {
        this.authorization.checkAuthState();
      } catch (err) {
        this.setState({ ...this.state, user: null, loading: false });
      }
    }*/
  }

  render() {
    const { user, loading, mode } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className="App">
          <Logo />
          {loading && <Spinner loading={this.state.loading} />}

          {!loading && !user && <SignIn authorization={this.authorization} />}

          {!loading && user && mode === "PLAY" && (
            <Primary
              connection={this.connection}
              authorization={this.authorization}
              media={this.media}
            />
          )}

          {!loading && user && mode === "AUXILLARY" && (
            <Aux
              connection={this.connection}
              authorization={this.authorization}
              media={this.media}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default App;
