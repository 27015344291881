import React, { Component } from "react";
import LocalMedia from "./LocalMedia";
import RemoteMedia from "./RemoteMedia";
import StartSessionForm from "./StartSession";
import UserMenu from "./UserMenu";

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      isConnected: false,
    };

    props.connection.on("onMasterOpen", () => {
      this.setState({ ...this.state, isConnected: true });
    });

    props.connection.on("onMasterClose", () => {
      this.setState({ ...this.state, isConnected: false });
    });
  }

  componentDidMount() {
    this.props.connection.init(
      this.props.authorization.user,
      this.props.authorization.credentials
    );
    this.props.connection.openMaster();
    this.props.media.initDevices();
  }

  componentWillUnmount() {
    this.props.connection.closeMaster();
  }

  render() {
    const { isConnected } = this.state;
    const { connection, authorization, media } = this.props;

    return (
      <React.Fragment>
        <RemoteMedia media={media} />
        <UserMenu authorization={authorization} connection={connection} />
        <LocalMedia connection={connection} media={media} />
        {isConnected && (
          <StartSessionForm connection={connection} media={media} />
        )}
      </React.Fragment>
    );
  }
}

export default App;
